<template>
  <admin-fillall-layout ref="content">
    <!--<title-bar ></title-bar>-->

    <button
      class="drone-select__navi image-button image-button-history pervious"
      @click="previous"
    />

    <!-- audio bar gregh_pen/NdVvbm-->
    <div class="holder">
      <div class="audio green-audio-player">
        <div class="loading">
          <div class="spinner" />
        </div>
        <div class="play-pause-btn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 18 24"
          >
            <path
              id="playPause"
              fill="white"
              fill-rule="evenodd"
              d="M18 12L0 24V0"
              class="play-pause-icon"
            />
          </svg>
        </div>

        <div class="controls">
          <span class="current-time">0:00</span>
          <div
            class="slider"
            data-direction="horizontal"
          >
            <div class="progress">
              <div
                id="progress-pin"
                class="pin"
                data-method="rewind"
              />
            </div>
          </div>
          <span class="total-time">0:00</span>
        </div>

        <div
          class="volume hidden"
          style="display:none"
        >
          <div class="volume-btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                id="speaker"
                fill="white"
                fill-rule="evenodd"
                d="M14.667 0v2.747c3.853 1.146 6.666 4.72 6.666 8.946 0 4.227-2.813 7.787-6.666 8.934v2.76C20 22.173 24 17.4 24 11.693 24 5.987 20 1.213 14.667 0zM18 11.693c0-2.36-1.333-4.386-3.333-5.373v10.707c2-.947 3.333-2.987 3.333-5.334zm-18-4v8h5.333L12 22.36V1.027L5.333 7.693H0z"
              />
            </svg>
          </div>
          <div class="volume-controls hidden">
            <div
              class="slider"
              data-direction="vertical"
            >
              <div class="progress">
                <div
                  id="volume-pin"
                  class="pin"
                  data-method="changeVolume"
                />
              </div>
            </div>
          </div>
        </div>
        <audio
          crossorigin
          muted
        >
          <source
            src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/355309/Swing_Jazz_Drum.mp3"
            type="audio/mpeg"
          >
        </audio>
      </div>
    </div>
    <!-- audio bar end -->

    <map-body
      ref="mapBody"
      :drone-id="$router.currentRoute.params.droneId"
      :history-id="$router.currentRoute.params.historyId"
      :camera-type="$router.currentRoute.params.cameraType"
    />
  </admin-fillall-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import { CopyObject } from '@/utils/common.js';
import { logger } from '@/logger/index';
import DroneApi from '@/services/api/domain/group/drone';
import TitleBar from '@/components/Monitor/TitleBar.vue';
import LoginUserApi from '@/services/api/domain/loginUser';
import { PlayerEvent } from './PlayerEvent.js';

import MapBody from './Map.vue';

export default {
  name: 'PlaybackMap',
  components: {
    TitleBar,
    MapBody,
  },
  data() {
    return {
      droneId: null,
      historyId: null,
    };
  },
  computed: {
    ...mapGetters({
      group: 'user/group',
      groupId: 'user/groupId',
    }),
  },
  watch: {

  },
  created() {
    // referesh f5 put data back, if one of them is not exists, then set all
    if (!this.$router.currentRoute.params.droneId || !this.$router.currentRoute.params.historyId || !this.$router.currentRoute.params.cameraType) {
      this.$router.currentRoute.params.droneId = this.$route.query.droneId;
      this.$router.currentRoute.params.historyId = this.$route.query.historyId;
      this.$router.currentRoute.params.cameraType = this.$route.query.cameraType;
    }
  },
  destroyed() {

  },
  async mounted() {
    // data send to map.vue!!!! drone history must handle in here,linkL attitude...
    logger.debug(`this.historyId:${this.historyId}`);
    if (this.$router.currentRoute.params.droneId != null) {
      this.droneId = this.$router.currentRoute.params.droneId;
    }
    logger.debug(`this.droneId:${this.droneId}`);
    this.historyId = this.$router.currentRoute.params.historyId;
    logger.debug(`this.historyId:${this.historyId}`);

    // if camera type is not found, player can keep playing, but don't initialize player.
    let { cameraType } = this.$router.currentRoute.params;

    DroneApi.get(this.group.id, this.$router.currentRoute.params.droneId).then((drone) => {
      // _this.drone = drone.data;
    });
    // const {meta,data}=await DroneApi.historyInfo(this.groupId,this.droneId,null,this.historyId);
    // logger.debug('historyInfo');

    // audio bar js:
    const audioPlayer = document.querySelector('.green-audio-player');
    const playPause = audioPlayer.querySelector('#playPause');
    const playpauseBtn = audioPlayer.querySelector('.play-pause-btn');
    const loading = audioPlayer.querySelector('.loading');
    const progress = audioPlayer.querySelector('.progress');
    const sliders = audioPlayer.querySelectorAll('.slider');
    const volumeBtn = audioPlayer.querySelector('.volume-btn');
    const volumeControls = audioPlayer.querySelector('.volume-controls');
    const volumeProgress = volumeControls.querySelector('.slider .progress');
    const player = audioPlayer.querySelector('audio');
    const currentTime = audioPlayer.querySelector('.current-time');
    const totalTime = audioPlayer.querySelector('.total-time');
    const speaker = audioPlayer.querySelector('#speaker');
    const draggableClasses = ['pin'];
    let currentlyDragged = null;
    let history_video;

    // gps properties
    let gpsCurrentTime = 0;
    let gps_total_seconds = 0;
    let gpsplayerclock;
    let gpsplayerpaused = true;
    const that = this;

    // event bus from vue
    PlayerEvent.$on('mapInitialized', (gps) => {
      gps_total_seconds = (new Date(gps[gps.length - 1].create_time).getTime() - new Date(gps[0].create_time).getTime()) / 1000;
      this.gps = gps;
      // init time with gps length
      totalTime.textContent = formatTime(gps_total_seconds);
    });

    initMapPlayer();

    /* if (cameraType != 'none') reEntryInitVideoPlayer();

    function reEntryInitPlayer() {
      // lock loading button
      makeOnLoadingStatus();
      try {
        initPlayer();
      } catch (e) {
        setTimeout(reEntryInit, 1000);
      }
    } */

    /* function initPlayer() {
      console.log('player initialization');

      // init history video
      history_video = videojs('history-video');
      window.test = history_video;
      history_video.ready(function () {
        // show play button
        this.on('loadedmetadata', () => {
          console.log('Metadata Loaded');
        });

        this.on('progress', () => {
          if (this.bufferedPercent() > 0) {
            makePlayStatus();
          }
        });
      });

      // overlay map, video
      const small_screen_layer_video = document.getElementById('small-screen-layer-video');
      const small_screen_layer_map = document.getElementById('small-screen-layer-map');
      const operators = document.getElementsByClassName('operators')[0];
      const group_button = document.getElementsByClassName('vertical-group-button')[0];

      let small_switch = false;
      small_screen_layer_video.addEventListener('click', () => {
        if (!small_switch) {
          small_screen_layer_map.className = 'map-body overlay player small-map';
          small_screen_layer_video.className = 'player fullscreen-video';

          operators.className = 'operators';
          group_button.className = 'vertical-group-button';
          setTimeout(() => { small_switch = true; }, 500);
        }
      });

      small_screen_layer_map.addEventListener('click', () => {
        if (small_switch) {
          small_screen_layer_map.className = 'map-body';
          small_screen_layer_video.className = 'overlay player';

          operators.className = 'overlay operators';
          group_button.className = 'overlay vertical-group-button';
          setTimeout(() => { small_switch = false; }, 500);
        }
      });
    } */

    function initMapPlayer() {
      playpauseBtn.addEventListener('click', togglePlay);

      window.addEventListener('mousedown', function (event) {
        if (!isDraggable(event.target)) return false;
        // change time
        rewind(event);
        currentlyDragged = event.target;
        const handleMethod = currentlyDragged.dataset.method;

        this.addEventListener('mousemove', window[handleMethod], false);
        window.addEventListener('mouseup', () => {
          currentlyDragged = false;
          window.removeEventListener('mousemove', window[handleMethod], false);
        }, false);
      });

      player.addEventListener('canplay', makePlayStatus);
      player.addEventListener('ended', () => {
        playPause.attributes.d.value = 'M18 12L0 24V0';
        gpsCurrentTime = 0;
      });
      window.addEventListener('resize', directionAware);
      sliders.forEach((slider) => {
        const pin = slider.querySelector('.pin');
        // slider.addEventListener('click', window[pin.dataset.method]);
        slider.addEventListener('click', (event) => {
          // var offset = slider.offset();
          // Then refer to
          // var x = event.pageX - offset.left;
          // gpsCurrentTime = 15;
          // updateProgress();
          rewind(event);
          directionAware();
        });
      });
    }

    function isDraggable(el) {
      let canDrag = false;
      const classes = Array.from(el.classList);
      draggableClasses.forEach((draggable) => {
        if (classes.indexOf(draggable) !== -1) canDrag = true;
      });
      return canDrag;
    }

    function inRange(event) {
      const rangeBox = getRangeBox(event);
      const rect = rangeBox.getBoundingClientRect();
      const { direction } = rangeBox.dataset;
      if (direction == 'horizontal') {
        var min = rangeBox.offsetLeft;
        var max = min + rangeBox.offsetWidth + 65;
        if (event.clientX < min || event.clientX > max) return false;
      } else {
        var min = rect.top;
        var max = min + rangeBox.offsetHeight;
        if (event.clientY < min || event.clientY > max) return false;
      }
      return true;
    }

    function updateProgress() {
      const current = gpsCurrentTime;
      const percent = (current / gps_total_seconds) * 100;
      progress.style.width = `${percent}%`;

      currentTime.textContent = formatTime(current);
      PlayerEvent.$emit('playerUpdate', current);
    }

    function getRangeBox(event) {
      let rangeBox = event.target;
      const el = currentlyDragged;
      if (event.type == 'click' && isDraggable(event.target)) {
        rangeBox = event.target.parentElement.parentElement;
      }
      if (event.type == 'mousemove') {
        rangeBox = el.parentElement.parentElement;
      }
      return rangeBox;
    }

    function getCoefficient(event) {
      const slider = getRangeBox(event);
      const rect = slider.getBoundingClientRect();
      let K = 0;
      if (slider.dataset.direction == 'horizontal') {
        const offsetX = event.clientX - slider.offsetLeft - 65;
        const width = slider.clientWidth;
        K = offsetX / width;
      } else if (slider.dataset.direction == 'vertical') {
        const height = slider.clientHeight;
        const offsetY = event.clientY - rect.top;
        K = 1 - offsetY / height;
      }
      return K;
    }

    function rewind(event) {
      if (inRange(event)) {
        gpsCurrentTime = gps_total_seconds * getCoefficient(event);
        updateProgress();

        PlayerEvent.$emit('playerChangedProcess', gpsCurrentTime);

        if (cameraType == 'none') {
          // no video play mode.
          return;
        }

        history_video.player_.currentTime(gpsCurrentTime);

        // force sync video
        makeOnLoadingStatus();
        pauseGPSPlayer();

        if (history_video.readyState() == 4) {
          makePlayStatus();
          playGPSPlayer();
        } else {
          makeOnLoadingStatus();
          setTimeout(() => {
            if (history_video.readyState() == 4) {
              makePlayStatus();
              playGPSPlayer();
            } else {
              // reset player again
              history_video.dispose();
              const originalSrc = history_video.src().toString();
              const originalType = history_video.currentType().toString();
              document.getElementById('small-screen-layer-video').innerHTML = `
							<video style="outline: none;" id="history-video" class="video-js vjs-default-skin" preload="auto">
								<source id="source" src="${originalSrc}" type="application/x-mpegURL">
							</video>`;
              history_video = videojs('history-video');
              history_video.reset();
              history_video.src(originalSrc);
              history_video.player_.currentTime(gpsCurrentTime);
              setTimeout(() => {
                makePlayStatus();
                if (history_video.readyState() == 4) {
                  playGPSPlayer();
                  history_video.player_.play();
                  history_video.player_.currentTime(gpsCurrentTime);
                } else {
                  pauseGPSPlayer();
                  history_video.player_.pause();
                  history_video.player_.currentTime(gpsCurrentTime);
                }
              }, 5000);
            }
          }, 6000);
        }
      }
    }

    function formatTime(time) {
      const min = Math.floor(time / 60);
      const sec = Math.floor(time % 60);
      return `${min}:${(sec < 10) ? (`0${sec}`) : sec}`;
    }

    function pauseGPSPlayer() {
      playPause.attributes.d.value = 'M18 12L0 24V0';
      gpsStop();
      clearAllInterval(); // force stop all
      PlayerEvent.$emit('stopVideo', null);
      PlayerEvent.$emit('playerChangedProcess', gpsCurrentTime);
    }

    function playGPSPlayer() {
      playPause.attributes.d.value = 'M0 0h6v24H0zM12 0h6v24h-6z';
      makePlayStatus();
      gpsPlay();
      PlayerEvent.$emit('playVideo', null);
      PlayerEvent.$emit('playerChangedProcess', gpsCurrentTime);
    }

    function togglePlay() {
      if (cameraType != 'none' && !history_video) {
        history_video = videojs('history-video');
        history_video.on('error', () => {
          cameraType = 'none';
          that.$set(that.$router.currentRoute.params, 'cameraType', 'none');
          that.$forceUpdate();
        });
        makeOnLoadingStatus();
        setTimeout(togglePlay, 2000);
        return;
      }

      if (cameraType != 'none' && history_video.bufferedPercent() <= 0) {
        makeOnLoadingStatus();
        setTimeout(togglePlay, 2000);
        return;
      }

      player.volume = 0;
      if (gpsplayerpaused) {
        playGPSPlayer();
      } else {
        pauseGPSPlayer();
      }
    }

    function makePlayStatus() {
      player.volume = 0;
      playpauseBtn.style.display = 'block';
      loading.style.display = 'none';
    }

    function makeOnLoadingStatus() {
      player.volume = 0;
      playpauseBtn.style.display = 'none';
      loading.style.display = 'block';
    }

    function directionAware() {
      if (window.innerHeight < 250) {
        volumeControls.style.bottom = '-54px';
        volumeControls.style.left = '54px';
      } else if (audioPlayer.offsetTop < 154) {
        volumeControls.style.bottom = '-164px';
        volumeControls.style.left = '-3px';
      } else {
        volumeControls.style.bottom = '52px';
        volumeControls.style.left = '-3px';
      }
    }

    function gpsPlay() {
      gpsplayerpaused = false;
      const timeAccurate = 1000;
      gpsplayerclock = setInterval(() => {
        // check is stop
        if (gpsCurrentTime >= gps_total_seconds) {
          playPause.attributes.d.value = 'M18 12L0 24V0';
          gpsCurrentTime = 0;
          pauseGPSPlayer();
          return;
        }
        gpsCurrentTime += (timeAccurate / 1000);
        updateProgress();
      }, timeAccurate);
    }

    function gpsStop() {
      gpsplayerpaused = true;
      clearInterval(gpsplayerclock);
    }

    function clearAllInterval() {
      let id = setInterval(() => {}, 9999);
      while (id > 0) { clearInterval(id--); }
    }
  },
  methods: {
    previous() {
      const interval_id = window.setInterval('', 9999); // Get a reference to the last
      for (let i = 1; i < interval_id; i++) window.clearInterval(i);

      let id = window.setTimeout(() => {}, 0);
      while (id--) {
        window.clearTimeout(id); // will do nothing if no timeout with id is present
      }

      this.$router.push({ name: 'historyList' });
    },
  },
};
</script>

<style lang="scss" scoped>
.image-button-history{
	position: absolute;
    left: 15px;
    top: 8px;
	width: 35px;
    height: 35px;
	background-image: url(/asserts/icon/dronelist-icon-previous.svg);
}
.holder {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .get-it-on-github {
    margin-top: 24px;
    margin-bottom: 24px;
    font-family: 'Roboto';
    color: #55606E;
  }
}
.audio.green-audio-player {
    width: 400px;
    min-width: 300px;
    height: 30px;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.07);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 4px;
    user-select: none;
    -webkit-user-select: none;
    background-color: #000000a1;
  .play-pause-btn {
    display: none;
    cursor: pointer;
  }
  .spinner {
    width: 18px;
    height: 18px;
    background-image: url('/asserts/icon/loading.png');
    background-size: cover;
    background-repeat: no-repeat;
    animation: spin 0.4s linear infinite;
  }
  .slider {
    flex-grow: 1;
    background-color: #D8D8D8;
    cursor: pointer;
    position: relative;
    .progress {
      background-color: white;
      border-radius: inherit;
      position: absolute;
      pointer-events: none;
      .pin {
        height: 16px;
        width: 16px;
        border-radius: 8px;
        background-color: white;
        position: absolute;
        pointer-events: all;
        box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.32);
      }
    }
  }
  .controls {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 18px;
    color: white;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    margin-left: 24px;
    margin-right: 24px;
    .slider {
      margin-left: 16px;
      margin-right: 16px;
      border-radius: 2px;
      height: 4px;
      .progress {
        width: 0;
        height: 100%;
        .pin {
          right: -8px;
          top: -6px;
        }
      }
    }
    span {
      cursor: default;
    }
  }
  .volume {
    position: relative;
    .volume-btn {
      cursor: pointer;
      &.open path {
        fill: #44BFA3;
      }
    }
    .volume-controls {
      width: 30px;
      height: 135px;
      background-color: rgba(0, 0, 0, 0.62);
      border-radius: 7px;
      position: absolute;
      left: -3px;
      bottom: 52px;
      flex-direction: column;
      align-items: center;
      display: flex;
      &.hidden {
        display: none;
      }
      .slider {
        margin-top: 12px;
        margin-bottom: 12px;
        width: 6px;
        border-radius: 3px;
        .progress {
          bottom: 0;
          height: 100%;
          width: 6px;
          .pin {
            left: -5px;
            top: -8px;
          }
        }
      }
    }
  }
}

</style>

<style>
.historyMode > .flight-action{
	opacity: 0.5;
}
.history-video-dimensions, .video-js {
  width: 100%;
  height: 100%;
}

</style>
