<template>
  <div>
    <div
      id="small-screen-layer-map"
      class="map-body"
    >
      <flight-map
        :edit-mode="false"
        :fly-area-guided-click="guideDrone"
        :show-nest="map.nest"
        :show-camera="map.camera"
        :operator-top="154"
      >
        <template
          v-for="(drone, index) in drones"
          v-if="activateDrone[index]"
        >
          <drone
            v-if="drone.liveStatus.gps && drone.liveStatus.gps.lat && drone.liveStatus.attitude"
            :key="`map-drone-${index}`"
            :status="droneStatus[index]"
            :index="index"
            :position="drone.liveStatus.gps"
            :attitude="drone.liveStatus.attitude"
            :mission="drone.liveStatus.current_mission"
            :selected="selectDrone && drone.id == selectDrone.id"
            @click="controllDrone(index)"
          />
          <fly-router
            v-if="missions[index]"
            :key="`mission${index}`"
            :edit-mode="false"
            :task="missions[index].tasks"
            :index="index"
            :history="drone.liveStatus.history"
            :use-normal-icon="false"
          />
        </template>
      </flight-map>

      <div
        id="small-screen-layer-video"
        class="overlay player"
      >
        <video
          id="history-video"
          style="outline: none;"
          class="video-js vjs-default-skin"
          preload="auto"
        >
          <source
            id="source"
            :src="getVideoPlaybackURL(this.historyId, this.cameraType, this.droneId)"
            type="application/x-mpegURL"
          >
        </video>
      </div>
    </div>

    <drone-control
      v-if="droneData != null"
      ref="droneControl"
      class="historyMode"
      :drone="droneData"
      :drone-mq="drones[0].liveStatus"
      :drone-mission="drones[0].liveStatus.current_mission"
      :status="{'detail': 'replay', 'simple': 'replay'}"
      :guided="false"
    />
  </div>
</template>

<script>
import { RadianToAngle } from '@/utils/math';
import Drone from '@/components/GoogleMaps/Drone.vue';
import DroneControl from '@/views/admin/monitor/map/DroneControl.vue';
import FlightMap from '@/components/GoogleMaps/FlightMap.vue';
import FlyRouter from '@/components/GoogleMaps/FlyRoute.vue';
import { logger } from '@/logger/index';
import DroneApi from '@/services/api/domain/group/drone';
import GroupApi from '@/services/api/domain/group';
import { mapActions, mapGetters } from 'vuex';
import { PlayerEvent } from './PlayerEvent';

export default {
  name: 'MonitorMap',
  components: {
    Drone,
    FlightMap,
    FlyRouter,
    DroneControl,
  },
  data() {
    return {
      selectDrone: null,
      drones: [],
      // droneId:null,
      droneUpdate: null,
      nestUpdate: null,
      timeout: 10000,

      droneData: null,
      map: {
        drone: true,
        nest: true,
        camera: true,
      },
      activateDrone: [],
      timer: null,
      gps: null,
      pointer: -1,
    };
  },
  computed: {
    ...mapGetters({
      group: 'user/group',
      groupId: 'user/groupId',
    }),
    missions() {
      return this.drones.map((drone) => {
        const { mission } = drone;
        if (mission == null) {
          return null;
        }
        return mission;
      });
    },
    droneStatus() {
      return this.drones.map((drone) => ({
        detail: 'replay',
        simple: 'replay',
      }));
    },
    droneIds() {
      return this.drones.map((drone) => drone.id);
    },

    statistics() {
      // here
      const total = this.drones.length;
      const statistics = this.droneStatus.reduce((acc, currentValue) => {
        acc[currentValue.simple] += 1;
        return acc;
      }, {
        standby: 0,
        charging: 0,
        error: 0,
        offline: 0,
      });

      return {
        total,
        ...statistics,
      };
    },
    ...mapGetters({
      monitorState: 'monitorState/monitor',
    }),
  },
  methods: {

    getVideoPlaybackURL(historyId, cameraType, droneId) {
      let folderAppliedCameraType;

      if (cameraType.toLowerCase() == 'rgb') {
        folderAppliedCameraType = 'RGB';
      } else if (cameraType.toLowerCase() == 'thermal') {
        folderAppliedCameraType = 'Thermal';
      } else {
        folderAppliedCameraType = 'MapOnly';
      }
      return `https://${window.location.host}/azure/_definst_/${historyId}-${folderAppliedCameraType}/${droneId}-${cameraType.toLowerCase()}/playlist.m3u8`;
    },
    closeController() {
      this.selectDrone = null;
    },
    controllDrone(index) {
      if (this.selectDrone != null) {
        this.closeController();
      }
      this.$nextTick(function () {
        this.selectDrone = this.drones[index];
      });
    },
    messageDroneUpdate(message, payload, destination) {
      if (destination[0] != this.group.domain) {
        return;
      }
      if (destination[1] != this.group.name) {
        return;
      }
      if (destination[2] === 'alert' || destination.length == 3) {
        return;
      }
      const droneIndex = this.droneIds.indexOf(destination[2]);
      if (droneIndex == -1) {
        // console.error('==> is not in drone list', destination[2], this.droneIds);
        return;
      }
      if (destination[3] === 'alert') {
        return;
      }

      const messageType = destination[3];
      // console.log(`==> drone ${destination[2]} (${droneIndex}) message ${messageType}`);
      if (messageType == 'heartbeat') {
        const lastHeartbeat = this.drones[droneIndex].liveStatus.heartbeat;
        if (lastHeartbeat.armed == false && payload.data.armed == true) {
          const data = {
            droneIndex,
            drone: this.drones[droneIndex],
          };
          this.cleanFlyHistory(data);
        }
      }
      if (messageType == 'gps') {
        logger.debug('messageType :gps');
        payload.data.lat = payload.data.latitude / Math.pow(10, 7);
        payload.data.lng = payload.data.longitude / Math.pow(10, 7);
        if (this.drones[droneIndex].liveStatus.heartbeat.mode != null) {
          const data = {
            droneIndex,
            drone: this.drones[droneIndex],
            location: payload.data,
          };
          const inMission = this.drones[droneIndex].liveStatus.heartbeat.armed;
          if (inMission === true) {
            this.addFlyHistory(data);
          }
          if (inMission === false) {
            this.setTakeoff(data);
          }
        }
      }
      if (messageType == 'attitude') {
        payload.data.pitchAngle = RadianToAngle(payload.data.pitch);
        payload.data.rollAngle = RadianToAngle(payload.data.roll);
        payload.data.yawAngle = RadianToAngle(payload.data.yaw);
      }
      // if (messageType == 'heartbeat') {
      this.drones[droneIndex].liveStatus.timeout.offline = false;
      this.drones[droneIndex].liveStatus.timeout.lastUpdate = new Date();
      // }
      // console.log(this.drones[droneIndex].liveStatus[messageType]);
      const originalData = this.drones[droneIndex].liveStatus[messageType];
      if (_.isEqual(originalData, payload.data)) {
        return;
      }
      this.$set(this.drones[droneIndex].liveStatus, messageType, payload.data);
    },
    guideDrone(event) {
      if (this.guided) {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        logger.debug(`lat:${lat}`);
        logger.debug(`lng:${lng}`);
        this.tempMakrs.splice(0, this.tempMakrs.length);
        const data = {
          sequence: null,
          command: 'nest',
          x: event.latLng.lat(),
          y: event.latLng.lng(),
          z: 30,
          speed: 4,
          frame: null,
          auto_continue: null,
          delay: 0,
          radius: null,
          liveStatus: {
            gps: {
              lat: event.latLng.lat(),
              lng: event.latLng.lng(),
            },
          },
          show: true,
        };
        this.tempMark = data;
        this.tempMakrs.push(data);
      }
    },
    ...mapActions({
      setTakeoff: 'monitorState/setTakeoff',
      // addFlyHistory :'monitorState/addFlightHistory',
      // cleanFlyHistory :'monitorState/cleanFlightHistory',
    }),
    addFlyHistory(data) {
      this.drones[0].liveStatus.history.push(data);
    },
    // cleanFlyHistory(data) {
    //   let length = this.drones[data.droneIndex].liveStatus.history.length;
    //   this.drones[data.droneIndex].liveStatus.history.splice(0, length);
    // },
    cleanStatus(drone) {
      this.$set(drone.liveStatus, 'heartbeat', {});
      this.$set(drone.liveStatus, 'attitude', {});
      this.$set(drone.liveStatus, 'battery_status', {});
      this.$set(drone.liveStatus, 'current_mission', {});
      this.$set(drone.liveStatus, 'hud', {});
      this.$set(drone.liveStatus, 'system_status', {});
      this.$set(drone.liveStatus, 'event', {});
      this.$set(drone.liveStatus, 'history', []);
    },
    updateDroneStatus() {
      /* move tag: 10DAS0312N4I1HX912BIEXYCVI9NQ034

		this.pointer++;
        logger.debug('pointer:'+this.pointer);
        if(this.gps[this.pointer]){
          let position=this.gps[this.pointer];
          logger.debug(position.gps.latitude);
          logger.debug(position.gps.longitude);
          logger.debug(position.gps.eph);
          logger.debug(position.gps.epv);
          logger.debug(position.gps.fix_type);
          logger.debug(position.gps.satellites_visible);
          logger.debug(position.create_time);
          position.gps.lat = position.gps.latitude ;
          position.gps.lng = position.gps.longitude ;
          this.$set(this.drones[0].liveStatus, 'gps', position.gps);
          this.addFlyHistory(position.gps);
		  //(new Date(window.test[127].create_time).getTime() - new Date(window.test[0].create_time).getTime()) / 1000 get seconds
		} */

    },
  },
  watch: {
    drones(now, old) {
      if (_.isEqual(now, old)) {

      }
      // for (let index in old) {
      //   this.$mqtt.unsubscribeDroneFacade(this.group, old[index]);
      // }
      // for (let index in now) {
      //   this.$mqtt.subscribeDroneFacade(this.group, now[index],this.messageDroneUpdate);
      // }
    },

  },
  async mounted() {
    const groupData = await GroupApi.get(this.groupId);
    this.drones[0] = {
      mission: {
        tasks: [],
      },
      liveStatus: {
        heartbeat: {},
        attitude: {},
        battery_status: {},
        current_mission: {},
        gps: {
          lat: 0,
          lng: 0,
        },
        hud: {},
        pilot_status: {},
        system_status: {},
        event: {},
        history: [],
        timeout: {
          offline: true,
          lastUpdate: null,
        },
      },
    };
    this.activateDrone = this.drones.map(() => true);
    this.selectDrone = this.drones[0];
    const { data } = await DroneApi.historyInfo(this.groupId, this.droneId, null, this.historyId);
    logger.debug('historyInfo');
    this.drones[0].mission.tasks = data.tasks;
    this.gps = data.records;
	  this.timer = setInterval(this.updateDroneStatus, 1000);
	  this.drones[0].liveStatus.gps.lat = data.records[0].gps.latitude;
	  this.drones[0].liveStatus.gps.lng = data.records[0].gps.longitude;

	  window.data = data;
	  window.data2 = this;

	  // init drone-control data
    const hasDroneName = groupData.data.drones.find((drone) => drone.id === this.droneId);
	  this.droneData = data;
	  this.droneData.name = hasDroneName ? hasDroneName.name : 'N/A';
	  this.drones[0].liveStatus.current_mission.name = 'N/A';
	  this.drones[0].liveStatus.current_mission.duration = Math.floor((new Date(this.gps[this.gps.length - 1].create_time) - new Date(this.gps[0].create_time)) / 1000);
	  this.$set(this.drones[0].liveStatus, 'alert', { time: this.gps.length - 1 });
	  // for player (detail)
	  // load script
    const videocss = document.createElement('link');
    videocss.setAttribute('href', 'https://vjs.zencdn.net/7.6.5/video-js.css');
    videocss.setAttribute('rel', 'stylesheet');
    document.head.appendChild(videocss);
    const videojs = document.createElement('script');
    videojs.setAttribute('src', 'https://vjs.zencdn.net/7.6.5/video.js');
    document.body.appendChild(videojs);
    const video = document.getElementById('history-video');

    PlayerEvent.$on('playVideo', () => {
      if (this.cameraType === 'none') return;
      const video = document.getElementById('history-video');
      video.player.play();
    });
    PlayerEvent.$on('stopVideo', () => {
      if (this.cameraType === 'none') return;
      const video = document.getElementById('history-video');
      video.player.pause();
    });

    PlayerEvent.$emit('mapInitialized', this.gps);

    // event bus from vue
    PlayerEvent.$on('playerUpdate', (pointer) => {
      // move tag: 10DAS0312N4I1HX912BIEXYCVI9NQ034
      this.pointer = parseInt(pointer);
      logger.debug(`pointer:${this.pointer}`);
      if (this.gps[this.pointer]) {
        const position = this.gps[this.pointer];
        logger.debug(position.gps.latitude);
        logger.debug(position.gps.longitude);
        logger.debug(position.gps.eph);
        logger.debug(position.gps.epv);
        logger.debug(position.gps.fix_type);
        logger.debug(position.gps.satellites_visible);
        logger.debug(position.create_time);
        position.gps.lat = position.gps.latitude;
        position.gps.lng = position.gps.longitude;

        this.$set(this.drones[0].liveStatus, 'gps', position.gps);
        this.$set(this.drones[0].liveStatus, 'hud', position.hud);
        this.$set(this.drones[0].liveStatus, 'battery_status', position.battrey);

        // fixed data
        this.drones[0].liveStatus.current_mission.duration = Math.floor((new Date(this.gps[this.gps.length - 1].create_time) - new Date(this.gps[0].create_time)) / 1000);
        this.$set(this.drones[0].liveStatus, 'alert', { time: this.gps.length - 1 });
        const timestamp = this.pointer;
        const hours = Math.floor(timestamp / 60 / 60);
        let minutes = Math.floor(timestamp / 60) - (hours * 60);
        let seconds = timestamp % 60;

        minutes = minutes < 10 ? `0${minutes}` : minutes;
        seconds = seconds < 10 ? `0${seconds}` : seconds;

        document.getElementsByClassName('stack-info')[3].getElementsByTagName('span')[0].innerText = `${minutes}:${seconds}`;
        // end fixed data

        try {
          position.attitude.pitchAngle = RadianToAngle(position.attitude.pitch);
          position.attitude.rollAngle = RadianToAngle(position.attitude.roll);
          position.attitude.yawAngle = RadianToAngle(position.attitude.yaw);
          this.$set(this.drones[0].liveStatus, 'attitude', position.attitude);

          position.heartbeat.time = this.pointer;
          this.$set(this.drones[0].liveStatus, 'heartbeat', position.heartbeat);
        } catch (e) { console.log('Some Position Data is not found!'); }

        this.addFlyHistory(position.gps);
        // (new Date(window.test[127].create_time).getTime() - new Date(window.test[0].create_time).getTime()) / 1000 get seconds
      }
    });
    // changed
    PlayerEvent.$on('playerChangedProcess', (pointer) => {
      this.pointer = parseInt(pointer);
      logger.debug(`pointer:${this.pointer}`);
      if (this.gps[this.pointer]) {
        this.$set(this.drones[0].liveStatus, 'gps', {});
        this.$set(this.drones[0].liveStatus, 'heartbeat', {});
        this.$set(this.drones[0].liveStatus, 'attitude', {});
        this.$set(this.drones[0].liveStatus, 'battery_status', {});
        this.$set(this.drones[0].liveStatus, 'hud', {});
        this.$set(this.drones[0].liveStatus, 'system_status', {});
        this.$set(this.drones[0].liveStatus, 'event', {});
        this.$set(this.drones[0].liveStatus, 'history', []);
        for (let i = 0; i < this.pointer; i++) {
          const position = this.gps[i];
          position.gps.lat = position.gps.latitude;
          position.gps.lng = position.gps.longitude;
          this.addFlyHistory(position.gps);
        }
        const position = this.gps[this.pointer];

        this.$set(this.drones[0].liveStatus, 'gps', position.gps);
        this.$set(this.drones[0].liveStatus, 'hud', position.hud);
        this.$set(this.drones[0].liveStatus, 'battery_status', position.battrey);

        // fixed data
        this.drones[0].liveStatus.current_mission.duration = Math.floor((new Date(this.gps[this.gps.length - 1].create_time) - new Date(this.gps[0].create_time)) / 1000);
        this.$set(this.drones[0].liveStatus, 'alert', { time: this.gps.length - 1 });
        const timestamp = this.pointer;
        const hours = Math.floor(timestamp / 60 / 60);
        let minutes = Math.floor(timestamp / 60) - (hours * 60);
        let seconds = timestamp % 60;

        minutes = minutes < 10 ? `0${minutes}` : minutes;
        seconds = seconds < 10 ? `0${seconds}` : seconds;

        document.getElementsByClassName('stack-info')[3].getElementsByTagName('span')[0].innerText = `${minutes}:${seconds}`;
        // end fixed data
        try {
          position.attitude.pitchAngle = RadianToAngle(position.attitude.pitch);
          position.attitude.rollAngle = RadianToAngle(position.attitude.roll);
          position.attitude.yawAngle = RadianToAngle(position.attitude.yaw);
          this.$set(this.drones[0].liveStatus, 'attitude', position.attitude);

          position.heartbeat.time = this.pointer;
          this.$set(this.drones[0].liveStatus, 'heartbeat', position.heartbeat);
        } catch (e) { console.log('Some Position Data is not found!'); }

        // changed player time:
        video.currentTime = pointer;
      }
    });
  },
  destroyed() {
    clearInterval(this.timer);
    if (this.selectDrone) {
      this.closeController();
    }
  },
  props: {
    droneId: {
      required: true,
      type: String,
      default: null,
    },
    historyId: {
      required: true,
      type: String,
      default: null,
	  },
	  cameraType: {
      required: true,
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>

.small-map{
	top: 63px !important;
    left: 15px !important;
}
.fullscreen-video{
	width: calc(100vw - 80px);
    height: calc(100vh - 104px);
    position: absolute;
    top: -15px;
    left: -15px;
    overflow-y: hidden;
    z-index: -1;
}

html, body, video {
	width: 100%;
	height: 100%;
	overflow: hidden;
	margin: 0;
	padding: 0;
}
.video-js{
	border:0;
	width: 100%;
	height: 100%;
}
@import '~@/styles/variables.scss';
.map-body {
  position: absolute;
  top: 48px;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0;
  background-color: black;
}
.overlay.player {
  top: 15px;
  left: 15px;
  width: 220px;
  height: 123.4px;
}
.map-bottom {
  bottom: 0;
  position: absolute;
  width: 100%;
}
.overlay.vertical-group-button {
  bottom: 140px !important;
}
/* drone control style */
.ai-bar{
	position: absolute;
	left:30px !important;
}
.attitude-indicator{
	bottom: 0px !important;
	top: 9px !important;
	background-image: url(/asserts/icon-debug/attitude-indicator.png) !important;
}
.ai_direction{
    transform: rotate(155deg);
	-webkit-animation: spin 40s linear infinite;
    -moz-animation: spin 40s linear infinite;
    animation: spin 40s linear infinite;
}
.ai_indicator{
	width: calc(133px - calc(153px - 133px)) !important;
    left: 17px !important;
    background-size: 135px;
    background-repeat: no-repeat;
    background-position: center;
    transform: rotate(14deg);
	-webkit-animation:spin 20s linear infinite;
    -moz-animation:spin 20s linear infinite;
    animation:spin 20s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
.status-section{
	position: absolute;
    left: 180px;
    width: 880;
	color: white;
	.topBar{
		display: inline-block;
		padding: 30px 30px 15px 30px;
		.droneName{
			margin-right: 35px;
			font-size: 1.5em;
			color: white;
			padding: 7px 20px 7px 40px;
			border-radius: 5px;
    		background: #000000c9;
		}
		.droneStatus{
			padding: 12px;
			width: 300px;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
		.onmission_staus{
			width: 5px;
			height: 5px;
			background: #00e5ff;
			position: relative;
			border-radius: 5px;
			vertical-align: middle;
			padding: 5px;
			float: inherit;
			top: 5px;
			left: -9px;
		}
		.droneDateTime{
			padding: 11px;
		}
	}
	.topBar > div{
		width: fit-content;
		float:left;
		margin-right: 30px;
	}

	.bottomBar{
		padding-left: 30px;
		width: 900px;
		height: 50px;
		.itemStatus {
			width: 110px;
			float: left;
			margin-left: -20px;
			text-align: center;
			.item_title{
				font-weight: 300;
				color: #d4d4d4;
			}
			div{
				font-weight: bold;
				margin-top: 5px;
			}
		}
	}
}
.utilbar{
	position: absolute;
    left: 940px;
    padding: 32px 32px 15px 32px;
	.utilbar_top{
		margin-bottom: 17px;
	}
}
.utilbar:before{
	content:'';
    position: absolute;
    top: 20px !important;
    bottom: 0 !important;
    width: 1px !important;
    height: 80% !important;
    background: white;
    right: 100%;
}
.dashboard-bar{
	position: absolute;
    bottom: 0;
    width: 100%;
    height: 150px;
    background: #3e3e3eb3;
}
@keyframes spin {
  from {
    transform: rotateZ(0);
  }
  to {
    transform: rotateZ(1turn);
  }
}
</style>
